import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-assinatura-modal',
  templateUrl: './assinatura-modal.component.html',
  styleUrls: ['./assinatura-modal.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AssinaturaModalComponent implements OnInit {

  @Input() title:string = "Assinar Minuta";
  @Input() showModal = false;
  @Input() codigoDocumentos:number[];
  @Input() loginDefault:string;
  
  @Output() cancelEvent = new EventEmitter();
  @Output() signEvent = new EventEmitter();
  form: FormGroup;
  @Input() isLoading = false

  constructor(private fb: FormBuilder) { }


  ngOnInit() {
    this.createForm();
    this.form.controls.usuario.setValue(this.loginDefault);
  }

  createForm() {
    this.form = this.fb.group({
      usuario: ['', [ Validators.required, Validators.maxLength(150) ]],
      senha: ['', [ Validators.required, Validators.maxLength(150) ]]
    });
  }

  cancelModal(): void {
    this.cancelEvent.emit();
  }

  
  sign() {
    let assinatura = 
    { 
      usuario: this.form.controls.usuario.value,
      senha: this.form.controls.senha.value,
      codigosArquivos: this.codigoDocumentos
    }
    this.signEvent.emit(assinatura);
  } 
}
