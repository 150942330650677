import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpf'
})
export class CpfPipe implements PipeTransform {
  transform(value: string, args?:string): string {
    if (!value) {
      return value;
    }
    let formattedCpf = value;
    if(args && args.toLowerCase() === 'anonimo'){
        formattedCpf = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.###.###-$4');
    }else {
        formattedCpf = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    return formattedCpf;
  }
}