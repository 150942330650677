import { SideNavInterface } from '../../shared/interfaces/side-nav.type';

export const MENU_CONFIG: SideNavInterface[] = [
    {
        path: '/minuta',
        title: 'Minutas',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'layout',
        submenu: [     
            // {
            //     path: '/minutas',
            //     title: 'Todas',
            //     iconType: 'nzIcon',
            //     iconTheme: 'outline',
            //     icon: 'layout',
            //     submenu: []
            //  },
            {
                path: '/minutas/criadas-por-mim',
                title: 'Criadas por mim',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'layout',
                submenu: []
             },
             {
                path: '/minutas/compartilhadas',
                title: 'Compartilhadas comigo',
                iconType: 'nzIcon',
                iconTheme: 'outline',
                icon: 'layout',
                submenu: []
             }
            ]
    },
    // {
    //     path: '/minhas-minutas',
    //     title: 'Criadas por mim',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'layout',
    //     submenu: []
    // },
    // {
    //     path: '/minutas-compartilhadas',
    //     title: 'Compartilhadas comigo',
    //     iconType: 'nzIcon',
    //     iconTheme: 'outline',
    //     icon: 'layout',
    //     submenu: []
    // }
   
]    