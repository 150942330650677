import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as jwt_decode from 'jwt-decode';
import { CookieService } from "ngx-cookie-service";
import { SessionStorageService } from "ngx-webstorage";
import { Observable, of } from "rxjs";
import { map, shareReplay, take, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { CS_ORGANIZACIONAL_URL, SEGURANCA_REDIRECT_URL_OLD, SEGURANCA_REDIRECT_MUDA_SETOR_URL} from "../../shared/constantes";
import { UsuarioAutenticado } from "../../shared/models/usuario-autenticado.model";

const COD_SETOR_GTI = 123;

@Injectable({
    providedIn: "root"
})
export class UsuarioAutenticadoService {

    private serviceUrl = `${environment.baseUrl}/autenticacao`;

    private foto$: Observable<string>;

    constructor(
        private http: HttpClient,
        private cookieService: CookieService,
        private sessionStorage: SessionStorageService) {}

    getUsuarioAutenticado(): Observable<UsuarioAutenticado> {
        // removido pois quando mudava o setor no SGD não estava mudando nas demais aplicações
        // let storedAuthenticatedUser = this.sessionStorage.retrieve('authenticatedUser');
        // if(storedAuthenticatedUser){
        //     return of(storedAuthenticatedUser);
        // }

        return this.http.get<UsuarioAutenticado>(this.serviceUrl).pipe(tap(user => this.storeAuthentication(user)), shareReplay(1));
    }

    getFoto(): Observable<string> {
        if(this.foto$ == null) {
            let idenMembro = jwt_decode(this.getCookieValue())['iden_membro'];
            this.foto$ = this.http.get<string>(`${CS_ORGANIZACIONAL_URL}/${idenMembro}`)
                .pipe(
                    map(res => `data:image/png;base64,${res['data']}`)
                );
        }

        return this.foto$;
    }

    isAuthenticated(): boolean {   
        if(!this.cookieService.check(this.getCookieName())) {
            this.clearStoredAuthentication();
            window.location.href = SEGURANCA_REDIRECT_URL_OLD;
            return false;
        }

        return true;
    }

    logout(): void {
        this.clearStoredAuthentication();
        this.cookieService.deleteAll('/', 'oab.org.br');
        window.location.href = SEGURANCA_REDIRECT_URL_OLD;
    }

    public getCookieValue(): string {
        return this.cookieService.get(this.getCookieName());
    }

    private getCookieName(): string {
        return environment.production ? ".CFOAB_AUTH" : ".CFOAB_AUTH_DEV";
    }

    storeAuthentication(user) {
        this.sessionStorage.store('authenticatedUser', user);
    }

    clearStoredAuthentication(){
        this.sessionStorage.clear('authenticatedUser');
    }

    trocarSetor(): void {
        this.clearStoredAuthentication();
        window.location.href = SEGURANCA_REDIRECT_MUDA_SETOR_URL;
    }

    isAuthenticatedGTI(){
        return this.isAuthenticated && this.sessionStorage.retrieve('authenticatedUser').setor.codiSetr === COD_SETOR_GTI;
    }

    checkCredenciais(usuario, senha): Observable<Boolean> {
        var credenciais = {usuario: usuario, senha: senha}
        return this.http.post<Boolean>(this.serviceUrl+"/check", credenciais)
        .pipe(take(1));
    }

}