import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { SEGURANCA_REDIRECT_URL_OLD } from "../../shared/constantes";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private msg: NzMessageService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let clone = req; 

    return next.handle(clone).pipe(
        catchError((error: HttpErrorResponse) => {
            if(error) {
              if(error.status === 401) {
                window.location.href = SEGURANCA_REDIRECT_URL_OLD;
              }

              if(error.status == 403) {
                this.msg.error(error.error.message?error.error.message:'Você não possui acesso a esse recurso.');
              }

              if(error.status == 500) {
                this.msg.error('Ops! Houve um erro inesperado.')
              }

              if(error.status == 504) {
                this.msg.error('Que pena! Estamos enfrentando instabilidades em nosso serviço. Por favor tente novamente em alguns instantes.');
              }

              if(error.status == 428) {
                this.msg.error(error.error.message);
              }
            }
            return throwError(error);
        })
    );
  }

}