import { Routes } from '@angular/router';


export const CommonLayout_ROUTES: Routes = [

    {
        path: '', 
        redirectTo: "minutas/criadas-por-mim",
        pathMatch: "full"
    },
    {
        path: 'minutas',
        data: {
            title: 'Minuta'
        },
        loadChildren: () => import('../../modules/admin/minuta/minuta.module').then(m => m.MinutaModule),
    }
];