<nz-modal [(nzVisible)]="showModal" [nzTitle]="title" 
    [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="cancelModal()" nzWidth="400px">
    <ng-template #modalContent>

        <form [formGroup]="form" nz-form nzLayout="vertical" novalidate>
            <div class="row">
                <div class="col-lg-12">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="usuario">Usuário</nz-form-label>
                        <nz-form-control nzErrorTip="Campo obrigatório">
                            <input nz-input id="usuario" formControlName="usuario" placeholder="Informe o usuário" [disabled]="loginDefault"/>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <nz-form-item>
                        <nz-form-label nzRequired nzFor="senha">Senha</nz-form-label>
                        <nz-form-control nzErrorTip="Campo obrigatório">
                            <nz-input-group [nzSuffix]="suffixTemplate">
                                <input nz-input [type]="passwordVisible ? 'text' : 'password'" id="senha" formControlName="senha" placeholder="Informe a senha"/>
                            </nz-input-group>
                            <ng-template #suffixTemplate>
                                <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
                             </ng-template>
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
        </form>
    </ng-template>
    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="cancelModal()">Sair</button>
        <button nz-button nzType="primary" (click)="sign()" [nzLoading]="isLoading" [disabled]="form.invalid">Assinar</button>
    </ng-template>
</nz-modal>